import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Content = function content ({
        formlogin,onChangeLogin,recaptchaRefLogin,onSubmitLogin,submitClick
    }){
    return (
        <section className="login" style={{backgroundImage: 'url("https://admin.radiocomunicaciones.co/uploads/2023-08/84c4308c57f0e002a70b68b097a47e4c.jpg")'}} >
            <div className="container grid">
                <div className="form colflex">
                    <div className="tittle grid">
                        <h2>Bienvenido</h2>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRefLogin}
                        size="invisible"
                        sitekey="6LeaIIkkAAAAALppbcYBsDBy5YsYI50NwTn5NF0o"
                    />
                    <form 
                        id="formLogin" 
                        action="" 
                        onSubmit={onSubmitLogin} 
                        className="colflex"
                        method="POST"
                    >
                        <input
                            type="hidden"
                            name="token"
                            value={formlogin.token}
                            onChange={onChangeLogin}
                        />
                        <label>Usuario</label>
                        <input
                            name="user"
                            type="text"
                            value={formlogin.user}
                            onChange={onChangeLogin}
                        />
                        <label >Contraseña</label>
                        <input
                            name="password"
                            type="password"
                            value={formlogin.password}
                            onChange={onChangeLogin}
                            autoComplete="off"
                        />
                        <a className="flex" onClick={()=>submitClick()}>
                            Acceder
                        </a>
                    </form>
                    <div className="logow grid">
                        <img src="/img/logo.svg" alt="radio"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Content