import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";


import Content from "../components/files/content";

const rootElement = document.getElementById("content_files");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 8
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class Files extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'files',
            url_admin:[],
            info:[],
            error: null,
            form:{
                name:''
            },
            files:[],
            loading: 0,
        }

        this._recaptchaRef = React.createRef()

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/files')
            let data = await res.json()

            datosFromApi = data[2]
            pages = data[2].length / ITEMS_FOR_PAGES
            items = [...data[2]].splice(currentPage,ITEMS_FOR_PAGES)

            this.setState({
                url_admin:data[0],
                info:data[1],
                files:items,
            })
            console.log(data[2])
            if(data[2].length > 0){
                setTimeout(()=>{
                    this.setState({
                        loading: 1,
                    })
                },2000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            files:items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            files:items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            files:items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        e.preventDefault()
        /*
        if(
            this.state.form.name == ''
        ){
            swal({
                text: "Debes agregar una letra",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }
        */

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/search_files`,config)
            let data = await res.json()
            
            swal.close()

            datosFromApi = data[0]
            pages = data[0].length / ITEMS_FOR_PAGES
            items = [...data[0]].splice(currentPage,ITEMS_FOR_PAGES)

            this.setState({
                files:items,
            })

        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    render() {
        return (
            <div>
                <div className={'loading '+(this.state.loading == 1?'loading_none':'')}>
                    <div>
                        <img 
                            className="pulse animated infinite" 
                            src='/img/logo.svg'
                        />
                    </div>
                </div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        url_admin={this.state.url_admin}
                        list={this.state.files}
                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}

                        form={this.state.form}
                        onChange={this.handleChangeForm}
                        onSubmit={this.formSubmit}
                        submitClick={this.submitClick}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_files')) {
    root.render(
        <Files />
    )
}