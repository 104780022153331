import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Contact = function contact ({
    info,text,onChange,
    form,submit,recaptchaRef,
    }){
    return (
        <section className="contact">
            <div className="content colflex">
                <div className="info colflex">
                    <h2>{text.title}</h2>
                    <p>{text.subtitle}</p>
                    <h3>Escríbenos</h3>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LeaIIkkAAAAALppbcYBsDBy5YsYI50NwTn5NF0o"
                    />
                    <form className="colflex" id="formContact">
                        <input 
                            type="text" 
                            placeholder="Nombre"
                            name="name"
                            value={form.name}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Empresa"
                            name="company"
                            value={form.company}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Teléfono"
                            name="phone"
                            value={form.phone}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Correo"
                            name="mail"
                            value={form.mail}
                            onChange={onChange}
                        />
                        <textarea 
                            rows="6"
                            placeholder="Mensaje..."
                            name="message"
                            value={form.message}
                            onChange={onChange}
                        ></textarea>

                        <div className="policy colflex">
                            <p>
                                Al enviar este mensaje autorizas <a href={'/img/Politica-de-tratamiento-de-datos-RADIOCOMUNICACIONES.pdf'} target="_blank">el uso y tratamiento de datos.</a>
                            </p>
                            <a 
                                className="btnFormContact" 
                                onClick={()=>submit()}
                            >
                                Enviar
                            </a>
                        </div>
                    </form>
                    <div className="directory colflex">
                        <div className="item colflex">
                            <h3>Llámanos</h3>
                            <p>{info.phone_one}</p>
                            <p>{info.phone_two}</p>
                            <p>{info.phone_three}</p>
                            <p>(+57) 304 670 4173</p>
                        </div>
                        <div className="item colflex">
                            <h3>Correos</h3>
                            <p>{info.mail_one}</p>
                            <p>{info.mail_two}</p>
                        </div>
                        <div className="item colflex">
                            <h3>SÍGUENOS</h3>
                            <div className="social flex">
                                <a href={info.instagram} target='_blank' className="flex">
                                    <img src="/img/instac.svg" alt="Ig-icon"/>
                                </a>
                                <a href={info.facebook} target='_blank' className="flex">
                                    <img src="/img/fbc.svg" alt="Fb-icon"/>
                                </a>
                            </div>
                        </div>
                        <div className="item colflex">
                            <h3>DIRECCIÓN</h3>
                            <p>{info.address}, {info.city}</p>
                        </div>
                    </div>
                </div>
                <img src={text.img} alt="contact-image"/>
            </div>
        </section>
    )
}

export default Contact